import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 523.000000 572.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,572.000000) scale(0.100000,-0.100000)">


<path d="M3340 5550 c0 -5 20 -32 45 -60 24 -27 55 -68 67 -90 25 -43 26 -44
48 -30 13 8 12 14 -5 46 -11 20 -32 50 -47 67 -16 16 -28 32 -28 36 0 4 -9 14
-20 24 -22 18 -60 23 -60 7z"/>
<path d="M3870 5347 c0 -8 7 -27 14 -43 8 -16 17 -45 21 -64 3 -19 8 -47 11
-62 5 -24 1 -29 -28 -42 -63 -26 -105 -36 -154 -36 -48 0 -50 2 -111 70 -33
38 -64 83 -68 99 -12 54 -41 31 -43 -33 -1 -21 -3 -40 -4 -41 -47 -32 -181
-151 -218 -193 -66 -73 -160 -262 -118 -236 7 4 8 3 4 -5 -4 -6 -11 -9 -15 -6
-8 4 -23 -39 -25 -72 -1 -6 -5 -15 -9 -19 -13 -13 -7 -118 8 -137 13 -18 14
-18 25 3 6 12 10 33 9 48 -7 72 70 294 114 330 18 15 47 5 47 -15 0 -9 -13
-37 -28 -62 -59 -97 -103 -309 -72 -346 23 -28 40 -17 40 24 0 21 5 63 10 92
6 30 13 67 16 83 2 16 9 37 15 48 5 10 7 24 3 31 -4 6 -3 9 1 4 5 -4 20 12 34
36 48 82 110 139 221 206 51 31 62 34 145 35 58 1 109 8 143 20 29 10 55 16
57 14 6 -5 -10 -107 -17 -114 -3 -3 -40 -18 -82 -34 -180 -67 -328 -206 -400
-377 -35 -83 -42 -135 -23 -152 13 -10 18 -8 31 18 9 16 16 35 16 40 0 53 140
281 173 281 7 0 18 -9 25 -20 11 -19 8 -27 -37 -76 -84 -95 -149 -261 -122
-312 17 -32 38 -16 46 34 12 79 80 197 156 269 78 74 114 94 219 125 66 20 80
21 120 10 25 -7 84 -14 133 -17 90 -6 100 -12 73 -48 -9 -12 -41 -24 -98 -36
-163 -33 -299 -111 -393 -228 -64 -80 -117 -186 -109 -218 10 -37 33 -26 55
24 40 95 194 274 220 257 23 -14 4 -52 -62 -121 -70 -74 -139 -192 -139 -238
0 -21 17 -45 32 -45 4 0 25 39 47 86 75 160 195 251 381 289 l86 18 69 -28
c41 -16 105 -32 155 -37 47 -5 86 -11 88 -12 1 -2 -18 -29 -44 -60 l-46 -57
-57 7 c-195 27 -353 -22 -492 -153 -72 -68 -99 -107 -99 -142 0 -38 22 -34 58
10 58 70 121 126 183 159 70 37 79 37 79 3 0 -24 -9 -33 -56 -57 -65 -34 -139
-98 -178 -155 -22 -31 -27 -49 -24 -76 l4 -35 -232 0 c-261 0 -237 8 -248 -80
-3 -25 -17 -128 -30 -230 -14 -102 -44 -320 -66 -485 -22 -165 -43 -316 -47
-335 l-6 -35 -8 35 c-4 19 -43 281 -85 583 l-78 547 -237 0 -236 0 13 -82 c42
-257 74 -477 74 -511 0 -22 -9 -54 -20 -72 -19 -31 -105 -105 -122 -105 -4 0
-8 -5 -8 -10 0 -6 32 -26 72 -45 81 -40 104 -58 125 -98 7 -15 43 -177 79
-360 36 -182 71 -335 77 -339 17 -11 705 -10 711 1 3 5 10 37 15 72 5 35 28
174 51 309 45 264 48 341 15 398 -10 17 -29 36 -42 43 -30 16 -29 27 2 35 33
8 92 75 115 130 17 38 84 403 105 573 6 40 12 55 26 58 10 3 21 14 25 26 11
35 136 151 194 179 71 36 139 48 262 48 93 0 103 -2 163 -34 34 -18 95 -41
135 -51 l72 -18 -43 -43 c-50 -51 -66 -53 -171 -24 -127 35 -247 24 -374 -34
-102 -47 -204 -144 -190 -182 9 -23 23 -17 72 30 55 54 164 113 224 122 38 6
43 5 37 -10 -4 -10 -7 -24 -7 -32 0 -8 -6 -14 -14 -14 -30 0 -159 -74 -211
-122 -42 -38 -55 -57 -55 -78 0 -47 18 -44 79 13 107 99 189 123 388 114 l131
-6 74 -46 c66 -41 180 -85 221 -85 48 0 16 -49 -76 -117 -59 -44 -82 -41 -203
23 -95 50 -181 74 -269 74 -143 0 -317 -78 -286 -129 8 -12 16 -11 53 6 76 36
149 53 230 53 68 0 78 -2 78 -17 0 -35 -15 -41 -110 -45 -83 -4 -104 -8 -165
-39 -52 -25 -72 -41 -79 -62 -16 -46 3 -51 70 -17 68 35 168 54 237 45 27 -3
92 -22 145 -41 53 -20 118 -42 144 -49 27 -7 71 -32 103 -59 31 -25 86 -60
122 -77 l65 -31 -29 -30 c-78 -82 -104 -81 -244 8 -49 31 -122 70 -162 88 -69
30 -78 31 -202 31 -147 -1 -205 -16 -205 -55 0 -21 3 -22 63 -13 132 18 304 2
323 -29 4 -6 0 -17 -9 -25 -13 -13 -23 -13 -86 2 -127 31 -294 3 -307 -52 -4
-13 -4 -27 0 -30 3 -3 40 2 82 12 129 30 246 7 361 -72 38 -26 98 -60 135 -78
48 -22 75 -42 96 -71 33 -45 88 -92 152 -128 l43 -24 -49 -31 c-81 -51 -96
-50 -160 6 -122 106 -162 135 -239 173 -104 51 -179 70 -290 72 -93 2 -135
-10 -135 -37 0 -12 16 -15 78 -15 122 0 203 -17 300 -63 45 -22 49 -28 28 -46
-10 -8 -26 -5 -67 13 -101 44 -235 64 -309 46 -47 -11 -78 -38 -63 -54 3 -3
46 -6 94 -6 112 0 176 -13 266 -56 56 -27 84 -49 135 -105 34 -40 79 -88 99
-108 27 -27 38 -48 43 -82 4 -25 20 -74 36 -108 17 -35 30 -64 30 -66 0 -1
-14 -5 -31 -7 -28 -4 -36 2 -98 76 -126 151 -230 229 -367 276 -110 39 -249
45 -261 13 -8 -22 10 -30 87 -37 37 -3 97 -15 133 -26 76 -23 177 -78 177 -96
0 -25 -33 -23 -83 5 -68 38 -173 70 -258 77 -58 6 -72 4 -89 -11 -36 -32 -25
-42 59 -49 218 -18 349 -105 467 -311 24 -43 29 -62 30 -135 1 -48 9 -104 18
-129 21 -58 21 -61 -13 -61 -52 0 -78 16 -91 57 -7 21 -33 79 -59 128 -50 98
-145 204 -234 262 -109 71 -257 105 -265 61 -2 -12 11 -20 55 -33 83 -24 182
-78 244 -134 52 -47 53 -48 32 -60 -20 -10 -29 -6 -82 36 -106 86 -264 148
-310 123 -34 -18 -25 -38 22 -50 137 -35 269 -125 336 -229 17 -27 45 -86 62
-132 l31 -84 -16 -66 c-9 -37 -16 -99 -16 -139 0 -69 -1 -71 -22 -64 -38 11
-44 21 -58 96 -25 143 -71 242 -160 340 -84 95 -249 192 -294 175 -33 -13 -14
-35 61 -72 56 -28 98 -59 157 -118 l81 -80 -28 -9 c-26 -9 -32 -6 -80 45 -58
61 -161 131 -218 148 -83 25 -122 -21 -43 -51 174 -65 295 -212 330 -401 15
-78 15 -83 -5 -129 -28 -65 -51 -164 -51 -223 l0 -48 -32 22 c-87 59 -87 58
-81 183 7 135 -4 190 -58 302 -28 57 -57 98 -102 142 -80 80 -96 91 -129 92
-43 0 -43 -19 -2 -53 104 -83 214 -238 183 -258 -29 -18 -45 -8 -72 46 -49
101 -163 205 -223 205 -48 0 -53 -19 -13 -49 63 -47 144 -137 170 -192 36 -73
49 -142 49 -256 0 -89 -2 -100 -35 -167 -19 -39 -43 -102 -52 -140 l-17 -69
-43 46 c-50 53 -52 67 -23 176 35 130 22 257 -36 374 -43 86 -133 187 -166
187 -34 0 -28 -22 26 -87 57 -69 89 -126 112 -200 l13 -43 -34 0 c-30 0 -35 4
-45 38 -16 53 -47 110 -89 162 -49 61 -72 79 -101 80 -53 0 -52 -14 8 -76 32
-33 72 -86 88 -119 29 -60 29 -61 28 -232 -1 -168 -1 -172 -26 -205 -48 -63
-97 -178 -112 -265 -4 -26 -1 -35 15 -44 26 -14 27 -13 38 47 11 55 44 146 67
181 13 21 23 23 92 23 l77 0 58 -56 c47 -46 57 -62 57 -90 0 -23 6 -37 19 -44
28 -15 31 -13 31 20 0 30 8 37 26 21 21 -20 134 -53 159 -47 14 4 25 10 25 14
0 4 -37 22 -83 40 -45 18 -92 40 -104 50 -22 17 -22 18 -8 85 8 38 25 93 39
123 l25 54 76 0 c68 0 81 -3 118 -29 23 -15 57 -36 75 -45 17 -9 32 -23 32
-31 0 -20 40 -41 50 -26 5 8 25 7 75 -4 51 -12 75 -14 92 -6 32 14 29 39 -4
46 -83 16 -159 38 -166 48 -12 17 0 153 18 214 17 57 8 52 133 81 53 12 111
-4 127 -35 18 -36 37 -53 57 -53 16 0 19 5 16 25 l-5 25 96 0 c99 0 141 13
141 45 0 19 -17 22 -75 13 -27 -5 -78 -5 -112 -2 -62 6 -63 7 -69 41 -6 34 0
130 11 176 5 16 27 31 83 55 70 29 83 32 142 26 36 -4 75 -14 87 -22 26 -16
53 -10 53 12 0 8 15 17 35 20 58 11 123 43 135 66 19 35 -1 42 -57 20 -28 -10
-73 -22 -101 -26 l-50 -6 -21 56 c-13 34 -21 79 -21 114 l1 57 63 57 c78 70
136 91 170 59 43 -40 100 -14 62 28 -16 18 -15 20 21 42 45 28 120 96 134 120
10 20 2 49 -13 49 -6 0 -33 -21 -61 -46 -28 -25 -73 -57 -99 -70 l-48 -24 -20
32 c-11 18 -27 56 -36 84 l-15 52 61 80 c33 45 69 84 80 87 11 3 43 24 72 46
29 21 63 39 76 39 27 0 62 17 62 30 0 5 -9 11 -19 15 -18 5 -17 10 15 58 24
35 34 61 32 81 -4 37 -25 45 -37 14 -7 -18 -56 -90 -87 -126 -6 -8 -111 56
-147 90 -44 42 -44 47 -5 139 17 40 42 73 87 115 58 53 66 58 104 55 45 -2 85
18 73 37 -4 7 -20 12 -37 12 -16 0 -29 4 -29 9 0 5 16 43 36 84 35 75 41 117
16 125 -7 2 -19 -12 -28 -35 -26 -65 -67 -143 -80 -154 -18 -15 -68 3 -143 51
-68 44 -71 53 -46 138 13 41 31 66 101 136 47 47 91 86 98 86 7 0 21 9 30 19
17 18 17 20 0 29 -16 9 -16 13 5 62 12 28 21 61 19 73 -6 40 -30 23 -54 -38
-40 -99 -42 -100 -119 -85 -36 7 -97 28 -136 46 l-71 34 3 79 c4 77 5 80 49
129 25 28 56 53 70 57 61 15 75 21 78 38 3 13 -3 17 -27 17 l-30 0 27 58 c27
60 36 142 15 149 -6 2 -19 -19 -28 -49 -21 -64 -49 -121 -69 -141 -13 -13 -24
-13 -75 -2 -33 7 -85 22 -114 34 -61 25 -67 38 -67 132 0 41 7 59 45 115 25
36 45 69 45 75 0 5 5 9 11 9 13 0 39 25 39 38 0 6 -5 12 -10 14 -7 2 -5 27 5
73 12 51 14 75 6 92 -16 36 -39 28 -46 -14 -4 -21 -14 -65 -24 -98 l-17 -60
-78 1 c-43 1 -104 9 -136 19 l-58 18 -19 81 c-17 74 -17 85 -3 123 10 29 22
44 38 48 23 6 51 50 38 62 -4 4 -16 1 -28 -6 -21 -13 -21 -13 -14 71 4 56 3
95 -5 118 -11 31 -34 59 -50 60 -3 0 -5 -55 -5 -122 1 -68 -3 -129 -7 -135
-10 -17 -147 -16 -201 0 -33 10 -41 19 -53 52 -7 22 -20 55 -29 73 -12 26 -14
50 -8 112 3 44 11 80 15 80 5 0 9 11 9 25 0 14 -6 25 -13 25 -8 0 -21 25 -31
61 -9 33 -24 71 -32 85 -17 26 -54 33 -54 11z"/>
<path d="M3685 5266 c-31 -31 -27 -58 11 -93 32 -30 40 -29 75 7 23 23 29 35
24 52 -10 31 -25 45 -57 53 -21 5 -33 1 -53 -19z"/>
<path d="M4080 4997 c-76 -39 -24 -161 54 -126 54 25 54 103 0 128 -30 13 -24
13 -54 -2z"/>
<path d="M4375 4675 c-14 -13 -25 -36 -25 -50 0 -30 41 -75 68 -75 35 0 72 38
72 73 0 38 -7 50 -37 66 -33 17 -51 14 -78 -14z"/>
<path d="M4615 4265 c-47 -46 -17 -115 49 -115 41 0 66 26 66 68 0 66 -69 94
-115 47z"/>
<path d="M4802 3857 c-29 -30 -28 -62 3 -92 30 -31 65 -32 93 -2 28 30 28 71
0 97 -29 27 -69 25 -96 -3z"/>
<path d="M1327 3828 c-43 -58 -47 -88 -47 -340 0 -220 -2 -242 -20 -278 -18
-35 -94 -107 -132 -124 -11 -5 -11 -7 0 -12 7 -3 35 -23 62 -45 84 -66 84 -67
90 -365 l5 -261 30 -49 c17 -26 50 -62 74 -79 l43 -30 464 -3 c313 -2 477 1
505 8 54 15 133 92 148 145 6 24 11 137 11 278 l0 237 -232 -2 -233 -3 -3 -98
-3 -97 -167 2 -167 3 -5 125 c-3 69 -10 134 -17 145 -15 27 -52 59 -87 77
l-28 15 30 12 c77 32 102 93 102 246 l0 115 170 0 169 0 3 -97 3 -98 233 -3
232 -2 0 237 c0 253 -6 294 -46 343 l-26 30 -568 0 -569 0 -24 -32z"/>
<path d="M4906 3399 c-33 -26 -34 -68 -3 -97 45 -43 117 -14 117 46 0 33 -8
45 -37 61 -31 16 -47 14 -77 -10z"/>
<path d="M218 3353 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M797 3129 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M71 3061 c-22 -14 -5 -41 27 -41 15 0 37 -6 47 -14 10 -7 27 -11 37
-8 23 6 89 -21 151 -60 53 -34 57 -50 32 -134 -12 -40 -32 -68 -99 -137 -47
-48 -92 -87 -100 -87 -8 0 -22 -8 -31 -17 -16 -16 -16 -17 0 -29 16 -12 15
-17 -6 -74 -20 -56 -21 -62 -6 -77 15 -15 17 -15 23 3 4 10 20 48 35 83 l29
63 47 -7 c62 -9 159 -41 201 -67 31 -19 32 -22 32 -89 0 -64 -2 -72 -33 -106
-19 -21 -42 -48 -53 -60 -10 -13 -34 -26 -54 -29 -19 -4 -41 -11 -48 -17 -21
-17 -14 -37 14 -37 21 0 25 -3 19 -17 -31 -74 -52 -160 -43 -176 15 -30 29
-15 53 55 12 37 32 83 45 103 20 33 25 35 58 30 55 -9 165 -45 187 -61 16 -12
20 -30 23 -88 4 -73 4 -73 -43 -142 -26 -38 -59 -79 -73 -90 -21 -18 -24 -24
-14 -36 9 -11 8 -30 -3 -87 -11 -52 -12 -77 -5 -91 14 -27 39 -25 44 3 15 78
26 125 35 150 10 26 13 27 80 27 41 0 95 -8 133 -20 l65 -20 18 -66 c10 -37
18 -79 17 -95 -1 -35 -29 -89 -46 -89 -16 0 -46 -32 -46 -48 0 -7 11 -12 25
-12 25 0 25 0 23 -94 -1 -98 14 -146 46 -146 12 0 14 23 13 118 -1 64 2 123 6
130 17 26 237 9 237 -19 0 -6 14 -38 30 -71 29 -57 30 -66 24 -137 -4 -42 -10
-78 -14 -81 -18 -12 -15 -60 3 -60 8 0 21 -26 31 -62 21 -77 40 -108 66 -108
24 0 25 14 5 69 -8 22 -18 64 -23 91 -9 49 -8 51 17 64 34 18 122 36 170 36
33 0 43 -6 66 -36 15 -19 42 -51 61 -69 19 -19 34 -39 34 -44 0 -5 4 -21 10
-36 10 -25 10 -25 29 -6 14 14 18 29 15 58 -5 37 -3 39 62 88 108 82 206 195
253 291 65 133 94 295 59 330 -15 15 -17 15 -26 -2 -5 -11 -12 -47 -16 -82 -8
-81 -49 -203 -90 -270 l-33 -52 -17 25 c-16 24 -15 27 8 64 71 111 110 302 75
359 -22 36 -39 27 -40 -20 0 -61 -23 -175 -46 -234 -40 -103 -134 -204 -240
-257 -28 -15 -54 -31 -58 -37 -3 -5 -41 -10 -83 -10 -43 0 -103 -6 -133 -14
-88 -23 -84 -23 -81 4 9 82 12 88 50 101 64 21 180 77 219 105 130 95 263 303
263 411 0 59 -43 51 -55 -10 -22 -104 -160 -312 -191 -286 -21 18 -17 43 12
70 79 72 166 272 142 324 -19 43 -39 25 -62 -56 -53 -182 -173 -304 -360 -363
-82 -27 -86 -27 -137 -12 -29 9 -88 16 -131 16 -84 0 -92 6 -62 48 8 12 22 22
30 22 39 0 195 46 249 73 81 40 174 115 223 180 58 77 105 174 98 203 -11 41
-34 27 -65 -38 -34 -73 -107 -166 -168 -215 l-42 -35 -7 28 c-6 23 -1 33 29
61 101 92 189 241 171 291 -16 45 -31 34 -73 -52 -30 -62 -58 -102 -104 -148
-76 -75 -141 -110 -264 -140 l-92 -22 -48 21 c-73 33 -113 43 -199 51 l-78 7
45 55 45 55 149 1 c138 0 155 2 212 26 109 46 240 149 279 220 16 27 18 64 5
72 -5 4 -49 -33 -98 -81 -85 -85 -182 -151 -203 -138 -5 3 -10 16 -10 28 1 16
16 31 61 57 120 68 203 162 197 223 -4 46 -29 44 -58 -4 -55 -91 -186 -178
-299 -199 -29 -5 -95 -10 -146 -10 -82 0 -97 3 -146 29 -30 16 -88 39 -129 52
-41 12 -77 24 -79 26 -3 2 13 21 34 43 46 47 65 49 170 20 79 -22 169 -25 247
-9 116 25 249 104 301 179 19 28 21 39 8 59 -7 11 -23 2 -73 -43 -62 -57 -153
-108 -215 -121 -32 -6 -33 -5 -33 24 0 26 5 31 35 40 62 17 160 78 208 130 51
54 55 67 33 97 -14 18 -17 17 -58 -26 -63 -65 -95 -88 -158 -112 -67 -25 -183
-33 -300 -20 -70 8 -93 15 -132 41 -55 37 -140 73 -215 91 -29 6 -53 15 -53
19 0 11 81 87 123 114 l37 25 53 -18 c28 -10 73 -30 98 -44 117 -66 240 -87
357 -62 131 27 215 78 192 116 -7 11 -17 9 -58 -8 -77 -33 -160 -52 -229 -52
-65 0 -78 8 -68 40 6 17 15 20 73 20 137 0 281 62 282 122 0 28 -14 28 -77 -2
-127 -61 -238 -58 -417 9 -60 23 -117 41 -127 41 -9 0 -41 21 -71 46 -29 25
-87 62 -128 81 -41 20 -77 39 -80 42 -9 10 -134 12 -149 2z"/>
<path d="M4969 2970 c-9 -5 -22 -23 -29 -39 -10 -25 -9 -34 6 -60 28 -47 78
-51 117 -8 51 58 -27 146 -94 107z"/>
<path d="M908 2963 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M218 2894 c-51 -27 -49 -93 3 -120 25 -12 34 -13 57 -2 54 26 52 101
-5 127 -28 13 -22 14 -55 -5z"/>
<path d="M60 2841 c0 -11 4 -22 9 -25 4 -3 6 6 3 19 -5 30 -12 33 -12 6z"/>
<path d="M4882 2437 c-45 -48 -14 -117 51 -117 42 0 67 26 67 68 0 65 -74 96
-118 49z"/>
<path d="M320 2420 c-41 -41 -12 -120 45 -120 30 0 75 41 75 68 0 59 -79 93
-120 52z"/>
<path d="M496 2019 c-18 -14 -26 -30 -26 -52 0 -38 7 -50 37 -66 33 -17 51
-14 78 14 33 32 32 70 -1 100 -33 30 -53 31 -88 4z"/>
<path d="M4706 2018 c-53 -48 -25 -128 44 -128 67 0 96 88 44 129 -34 27 -57
26 -88 -1z"/>
<path d="M4395 1635 c-32 -31 -32 -69 1 -102 20 -20 31 -24 57 -19 64 12 86
73 42 120 -30 33 -68 34 -100 1z"/>
<path d="M732 1610 c-59 -55 6 -152 77 -115 49 25 56 82 15 114 -34 27 -64 27
-92 1z"/>
<path d="M2536 1572 c-8 -14 16 -82 27 -75 5 3 6 -4 2 -18 -5 -19 -4 -21 4 -9
9 12 11 13 11 1 0 -8 2 -12 5 -9 7 7 -15 58 -32 76 -17 17 -7 33 15 26 14 -5
15 -4 2 5 -18 13 -27 14 -34 3z"/>
<path d="M2661 1544 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2680 1553 c0 -6 8 -17 18 -24 16 -13 16 -13 2 6 -8 11 -16 22 -17
24 -2 2 -3 0 -3 -6z"/>
<path d="M2594 1495 c6 -25 12 -41 14 -35 6 16 -8 80 -17 80 -4 0 -3 -20 3
-45z"/>
<path d="M2671 1504 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2676 1450 c8 -43 14 -51 14 -19 0 13 -5 31 -10 39 -7 11 -8 5 -4
-20z"/>
<path d="M2612 1420 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2731 1414 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2562 1380 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M2689 1365 c-6 -40 -4 -53 7 -43 3 3 4 23 2 44 l-3 39 -6 -40z"/>
<path d="M4086 1325 c-59 -60 10 -155 83 -114 63 36 42 121 -31 127 -23 2 -41
-3 -52 -13z"/>
<path d="M1046 1295 c-29 -26 -32 -45 -15 -78 16 -30 28 -37 65 -37 52 0 83
53 59 99 -24 48 -68 54 -109 16z"/>
<path d="M2607 1313 c-10 -19 -17 -64 -9 -59 6 3 12 19 14 36 5 30 3 37 -5 23z"/>
<path d="M2550 1285 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M2681 1284 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2710 1286 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M2514 1193 c-17 -35 -27 -63 -23 -63 4 0 18 21 31 47 14 29 24 40 26
30 2 -10 8 -17 13 -17 6 0 7 5 4 11 -4 6 -10 20 -14 32 -5 19 -11 12 -37 -40z"/>
<path d="M2662 1216 c-16 -60 -16 -60 -1 -65 7 -2 9 1 5 8 -4 6 -2 27 4 46 6
19 10 40 8 46 -2 6 -9 -10 -16 -35z"/>
<path d="M2580 1220 c0 -11 2 -20 4 -20 2 0 6 9 9 20 3 11 1 20 -4 20 -5 0 -9
-9 -9 -20z"/>
<path d="M2594 1175 c11 -8 25 -15 30 -15 6 0 2 7 -8 15 -11 8 -25 15 -30 15
-6 0 -2 -7 8 -15z"/>
<path d="M2666 1114 c-36 -76 -28 -78 11 -3 17 32 28 59 24 59 -5 0 -21 -25
-35 -56z"/>
<path d="M2456 1096 c-30 -46 -58 -101 -43 -86 16 17 70 110 64 110 -3 0 -12
-11 -21 -24z"/>
<path d="M2540 1095 c-11 -13 -10 -14 4 -9 9 3 16 10 16 15 0 13 -6 11 -20 -6z"/>
<path d="M3700 1063 c-64 -23 -44 -143 24 -143 32 0 76 43 76 75 0 48 -54 85
-100 68z"/>
<path d="M2615 1029 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M1421 1004 c-12 -15 -21 -34 -21 -44 0 -31 39 -70 70 -70 31 0 70 39
70 70 0 31 -39 70 -70 70 -19 0 -36 -9 -49 -26z"/>
<path d="M2582 979 c-19 -30 -8 -34 12 -4 9 14 13 25 9 25 -5 0 -14 -9 -21
-21z"/>
<path d="M2303 874 c-26 -26 -37 -38 -24 -28 41 32 86 74 79 74 -3 0 -28 -21
-55 -46z"/>
<path d="M2378 893 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2535 879 c-10 -30 -2 -32 12 -3 6 13 7 24 3 24 -5 0 -12 -9 -15 -21z"/>
<path d="M3272 867 c-12 -13 -22 -33 -22 -46 0 -28 42 -71 70 -71 29 0 70 44
70 74 0 32 -36 66 -70 66 -15 0 -36 -10 -48 -23z"/>
<path d="M2286 799 c-25 -28 -46 -56 -46 -62 0 -6 7 0 16 13 10 14 33 42 53
63 19 20 32 37 29 37 -3 0 -27 -23 -52 -51z"/>
<path d="M1698 819 c-16 -17 -14 -21 24 -81 42 -66 80 -104 112 -114 38 -12
34 13 -10 61 -25 28 -50 59 -55 70 -36 75 -49 87 -71 64z"/>
<path d="M2468 810 c34 -16 62 -34 62 -39 0 -11 86 -101 97 -101 4 0 -14 22
-40 48 -26 26 -47 53 -47 59 0 11 -67 46 -110 57 -14 3 3 -7 38 -24z"/>
<path d="M2572 765 c28 -36 56 -65 61 -65 5 0 -14 26 -42 57 -28 31 -51 61
-51 65 0 4 -5 8 -10 8 -6 0 13 -29 42 -65z"/>
<path d="M2225 791 c-12 -23 -12 -31 -2 -37 6 -4 9 -4 5 1 -4 4 -1 20 8 36 8
16 12 29 10 29 -3 0 -12 -13 -21 -29z"/>
<path d="M2373 779 c-26 -9 -46 -43 -40 -67 4 -13 5 -11 6 5 2 34 47 64 77 53
14 -6 23 -6 20 -1 -9 14 -40 19 -63 10z"/>
<path d="M2190 731 c-6 -12 -7 -21 -1 -21 10 0 24 30 17 37 -2 3 -9 -5 -16
-16z"/>
<path d="M2452 715 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2206 685 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0 -7
-7 -4 -15z"/>
<path d="M2364 665 c11 -8 22 -12 25 -9 6 6 -19 24 -34 24 -5 -1 -1 -7 9 -15z"/>
<path d="M2420 670 c-8 -5 -12 -11 -10 -14 3 -2 14 2 24 10 22 15 10 19 -14 4z"/>
<path d="M3110 664 c0 -23 18 -44 37 -44 7 0 13 10 13 22 0 12 -3 18 -7 15 -3
-4 -15 0 -25 10 -17 15 -18 15 -18 -3z"/>
<path d="M2695 650 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"/>
<path d="M2720 640 c0 -9 61 -43 67 -36 2 2 -12 13 -32 24 -19 12 -35 17 -35
12z"/>
<path d="M2152 614 c3 -20 22 -44 34 -44 3 0 1 6 -5 13 -6 8 -9 16 -7 18 2 2
-3 10 -11 18 -12 11 -14 10 -11 -5z"/>
<path d="M2696 616 c21 -15 30 -14 19 3 -3 6 -14 11 -23 11 -13 0 -13 -2 4
-14z"/>
<path d="M2730 600 c0 -12 107 -53 122 -47 8 4 7 6 -5 6 -9 1 -25 5 -35 10
-45 25 -82 39 -82 31z"/>
<path d="M2825 586 c11 -7 31 -16 45 -20 14 -4 8 2 -14 14 -46 23 -59 26 -31
6z"/>
<path d="M1001 366 c-17 -20 -17 -212 0 -232 9 -11 31 -14 98 -12 l86 3 3 43
3 42 -35 0 c-25 0 -36 -4 -36 -15 0 -10 -10 -15 -30 -15 -25 0 -30 4 -30 23 0
13 -5 28 -11 34 -8 8 -8 13 0 17 6 4 11 16 11 27 0 29 26 34 64 14 44 -24 66
-15 66 28 0 18 -5 38 -12 45 -18 18 -162 16 -177 -2z"/>
<path d="M1210 349 c0 -17 5 -39 11 -50 7 -15 7 -24 -1 -34 -7 -8 -8 -20 -3
-27 13 -20 33 -81 33 -100 0 -15 9 -18 50 -18 48 0 50 1 65 37 9 21 13 49 10
64 -4 16 -1 37 5 49 6 11 12 41 14 65 2 42 1 45 -23 45 -22 0 -28 -7 -39 -40
-6 -22 -12 -59 -12 -82 l0 -43 -20 25 c-12 16 -20 41 -20 67 0 59 -8 73 -41
73 -26 0 -29 -4 -29 -31z"/>
<path d="M1416 292 c1 -180 0 -174 37 -170 30 3 32 6 35 46 2 23 6 42 10 42 4
0 16 -19 27 -42 17 -36 26 -44 53 -46 18 -2 32 0 32 5 0 4 -11 30 -25 57 -32
62 -31 72 4 140 l30 56 -40 0 c-26 0 -39 -4 -39 -13 0 -18 -28 -75 -41 -82 -5
-4 -9 16 -9 44 l0 51 -37 0 -38 0 1 -88z"/>
<path d="M1640 335 c0 -24 -5 -56 -12 -70 -9 -19 -9 -28 0 -37 7 -7 12 -34 12
-60 l0 -48 90 0 90 0 0 130 0 130 -90 0 -90 0 0 -45z m126 -48 c3 -13 4 -41 2
-63 -3 -37 -5 -39 -40 -42 l-38 -3 0 59 c0 32 3 62 7 65 3 4 19 7 34 7 22 0
30 -6 35 -23z"/>
<path d="M1860 322 c0 -32 -5 -63 -11 -69 -8 -8 -8 -13 0 -17 6 -4 11 -32 11
-62 l0 -55 33 3 c30 3 32 6 35 46 2 23 6 42 10 42 4 0 16 -19 27 -43 18 -37
24 -42 58 -45 l39 -3 -6 58 c-7 71 -7 75 0 146 l6 57 -36 0 -36 0 0 -55 c0
-30 -3 -55 -7 -55 -5 0 -19 25 -33 55 -23 50 -28 55 -57 55 l-33 0 0 -58z"/>
<path d="M2082 253 l3 -128 35 0 c35 0 35 0 40 53 l5 52 25 -55 c23 -50 28
-55 58 -55 17 0 32 1 33 3 8 33 8 92 -1 102 -8 10 -8 19 -1 34 12 22 15 80 5
105 -4 10 -18 16 -35 16 -27 0 -29 -3 -29 -37 0 -67 -16 -73 -41 -15 -22 50
-24 52 -61 52 l-39 0 3 -127z"/>
<path d="M2320 327 c0 -29 -5 -58 -12 -65 -9 -9 -9 -15 0 -24 7 -7 12 -36 12
-65 l0 -53 83 0 c77 0 83 2 93 25 7 13 9 26 6 30 -3 3 -32 5 -64 5 -45 0 -58
3 -58 15 0 10 10 15 33 15 25 0 35 6 45 27 18 39 16 42 -30 45 -59 4 -54 22 7
26 43 2 52 7 63 29 6 15 12 31 12 35 0 4 -43 8 -95 8 l-95 0 0 -53z"/>
<path d="M2557 373 c-4 -3 -7 -23 -7 -44 0 -21 -5 -49 -11 -63 -9 -20 -9 -29
0 -40 7 -8 12 -30 11 -48 -4 -60 -6 -58 90 -58 l90 0 0 35 c0 19 1 38 3 43 5
15 -53 13 -59 -2 -4 -10 -19 -16 -41 -16 l-34 0 3 63 c3 60 4 62 33 65 18 2
33 -2 41 -12 18 -25 62 -5 58 26 -1 12 -3 31 -3 41 -1 15 -12 17 -84 17 -46 0
-87 -3 -90 -7z"/>
<path d="M2752 345 c-3 -34 -1 -35 32 -35 l34 0 1 -85 c0 -47 3 -90 6 -95 3
-6 17 -10 31 -10 24 0 24 1 24 89 0 80 2 90 19 96 11 3 29 21 40 40 l20 35
-102 0 -103 0 -2 -35z"/>
<path d="M3112 368 c-10 -14 -16 -93 -14 -190 l2 -58 88 0 c98 0 112 7 112 57
0 31 -2 33 -35 33 -24 0 -35 -5 -35 -15 0 -10 -10 -15 -30 -15 -25 0 -31 5
-38 33 -18 63 27 119 66 84 25 -22 72 -11 72 17 -1 62 -6 66 -95 66 -54 0 -86
-4 -93 -12z"/>
<path d="M3333 353 c-12 -44 -6 -202 8 -219 9 -11 31 -14 98 -12 l86 3 3 48
c2 28 -2 55 -9 63 -9 11 -9 18 -1 26 17 17 15 87 -2 104 -10 10 -39 14 -95 14
-79 0 -81 0 -88 -27z m125 -110 l3 -63 -31 0 -30 0 0 58 c0 65 5 74 35 70 17
-3 20 -11 23 -65z"/>
<path d="M3570 335 c0 -25 -5 -54 -12 -64 -9 -15 -9 -25 0 -46 7 -14 12 -44
12 -65 0 -36 3 -40 25 -40 24 0 25 2 25 70 0 40 4 70 10 70 6 0 10 -6 10 -14
0 -8 4 -16 8 -18 4 -1 15 -18 24 -37 l16 -34 21 34 c12 19 21 40 21 47 0 6 5
12 10 12 6 0 10 -29 10 -66 l0 -65 38 3 37 3 -2 50 c-3 64 -3 78 1 148 l4 57
-49 0 c-32 0 -49 -4 -49 -12 0 -7 -9 -28 -21 -47 l-21 -34 -16 34 c-23 48 -36
59 -71 59 -30 0 -31 -1 -31 -45z"/>
<path d="M3956 363 c-8 -26 -6 -188 3 -236 0 -5 16 -7 34 -5 30 3 32 6 33 41
1 20 4 40 8 44 7 7 6 9 32 -42 17 -35 24 -40 54 -40 33 0 35 2 38 39 2 22 -2
50 -9 63 -10 18 -10 26 -1 35 7 7 12 36 12 65 l0 53 -40 0 -40 0 0 -57 -1 -58
-27 57 c-24 52 -30 58 -59 58 -22 0 -33 -6 -37 -17z"/>
<path d="M4190 361 c-5 -11 -10 -61 -10 -111 0 -129 1 -130 111 -130 l90 0 -3
80 c-2 44 -7 80 -11 80 -4 0 -19 0 -33 0 -26 0 -26 -1 -22 -50 l5 -50 -34 0
-34 0 3 63 3 62 48 5 c40 4 50 10 62 34 8 15 15 30 15 32 0 2 -40 4 -90 4 -78
0 -91 -2 -100 -19z"/>
<path d="M2997 163 c-4 -3 -7 -15 -7 -25 0 -13 7 -18 25 -18 20 0 25 5 25 25
0 18 -5 25 -18 25 -10 0 -22 -3 -25 -7z"/>
<path d="M3851 156 c-17 -20 -6 -36 25 -36 21 0 25 4 22 22 -4 27 -30 35 -47
14z"/>
<path d="M4413 163 c-7 -2 -13 -13 -13 -24 0 -14 7 -19 30 -19 23 0 30 5 30
19 0 15 -23 35 -33 30 -1 0 -8 -3 -14 -6z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
